// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-chuppah-js": () => import("./../../../src/pages/chuppah.js" /* webpackChunkName: "component---src-pages-chuppah-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-party-js": () => import("./../../../src/pages/party.js" /* webpackChunkName: "component---src-pages-party-js" */),
  "component---src-pages-photos-index-js": () => import("./../../../src/pages/photos/index.js" /* webpackChunkName: "component---src-pages-photos-index-js" */),
  "component---src-pages-photos-past-js": () => import("./../../../src/pages/photos/past.js" /* webpackChunkName: "component---src-pages-photos-past-js" */),
  "component---src-pages-rsvp-js": () => import("./../../../src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-week-index-js": () => import("./../../../src/pages/week/index.js" /* webpackChunkName: "component---src-pages-week-index-js" */),
  "component---src-pages-week-rsvp-js": () => import("./../../../src/pages/week/rsvp.js" /* webpackChunkName: "component---src-pages-week-rsvp-js" */)
}

